<template>
    <div class="col bg-white p-3 pb-0 m-0 justify-content-center rounded-1 bg-form">
        <div class="row gy-2 pb-3">
            <div class="col-12">
                <div class="row">
                    <div class="col fz-12 py-0 timer-box">
                        Claim Your Offer Now!
                        <div class="text-white rounded-pill timer py-1 px-2">
                            <img src="../../assets/icons/timer.svg" alt=""> <vue-countdown :time="2700000"
                                v-slot="{ minutes, seconds }">
                                {{ minutes }}:{{ seconds }}
                            </vue-countdown>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <form class="needs-validation" novalidate id="landinForm" @submit.prevent="submitForm">
                    <div class="row">
                        <div class="col-12" v-show="websiteStore.stepNumber === 1">
                            <div class="mb-2">
                                <input type="text" class="form-control p-lg-3" :class="{ 'is-valid': form.firstName }" placeholder="First name"
                                    aria-label="First name" v-model="form.firstName" id="firstName" required>
                            </div>
                            <div class="mb-2">
                                <input type="text" class="form-control p-lg-3" placeholder="Last name"
                                    aria-label="Last name" v-model="form.lastName" id="lastName" required>
                            </div>
                        </div>
                        <div class="col-12" v-show="websiteStore.stepNumber === 2">
                            <div class="mb-2">
                                <input type="Email" class="form-control p-lg-3"  placeholder="Email" aria-label="Email"
                                    v-model="form.email" id="email" required>
                      
                            </div>
                            <div class="mb-2">
                                <input type="tel" class="form-control p-lg-3" placeholder="Phone Number"
                                    aria-label="Phone Number" maxlength="10" minlength="10" pattern="^\d{10}$"
                                    v-model="form.phone" id="phoneNumber" required>
                          
                            </div>
                        </div>
                        <div v-show="websiteStore.stepNumber === 3">
                            <div class="col-12 fs-6">
                                Do you have fixed dates?
                            </div>
                            <div class="col-12 d-flex align-items-center mb-2 fs-14">
                                <input type="radio" name="role" value="yes" id="one" checked
                                    @click="setDatesForm(false)">
                                <label class="role px-2" for="one">Yes</label>
                                <input type="radio" name="role" value="no" id="two" @click="setDatesForm(true)">
                                <label class="role px-2" for="two">No</label>
                            </div>
                            <div class="row">
                                <div class="col-6 " :class="{ 'p-4': disableDatesForm }">
                                    <div class="mb-2 h-100">
                                        <DatePicker v-model="arrivalD" :enable-time-picker="false" placeholder="Arrival"
                                            :disabled="disableDatesForm"  :state="this.websiteStore.arrivalDate"
                                             id="datePickerComponent" v-if="disableDatesForm == false">
                                            <template #input-icon>
                                                <img class="input-slot-image" src="../../assets/icons/calendar_month.svg" />
                                            </template>
                                        </DatePicker>
                                    </div>
                                </div>
                                <div class="col-6" :class="{ 'p-4': disableDatesForm }">
                                    <div class="mb-2 h-100">
                                        <DatePicker v-model="departureD" :enable-time-picker="false" placeholder="Departure"
                                            :disabled="disableDatesForm"  :state="this.websiteStore.departureDate"
                                            class="" id="datePickerDeparture" v-if="disableDatesForm == false">
                                            <template #input-icon>
                                                <img class="input-slot-image" src="../../assets/icons/calendar_month.svg" />
                                            </template>
                                        </DatePicker>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12" v-show="websiteStore.stepNumber === 4">
                            <div
                                class="mb-2 form-control py-lg-5 ps-1 border-0 px-0 text-center justify-content-center">
                                <input class="form-check-input" :class="{ 'is-valid': form.termsAgreement }"
                                    type="checkbox" id="termsAgreement" v-model="form.termsAgreement">
                                <label class="ps-lg-3 pt-1 fs-6 ps-2" for="flexCheckDefault">
                                    <div type="button" class="p-0 m-0" data-bs-toggle="modal"
                                        data-bs-target="#termsModal">
                                        I agree to the <span class="tnc-lbl">Terms & Conditions.</span>
                                    </div>
                                </label>
                            </div>
                            <TheTermsModal />
                        </div>
                        <div class="col-2 ps-1" @click="websiteStore.setMinusOneStepNumber()">
                            <div class="btn p-lg-3"><img src="../../assets/icons/left_arrow.svg" alt="">
                            </div>
                        </div>
                        <div class="col">
                            <div class="btn form-control btn-next p-lg-3"
                                @click="websiteStore.setPlusOneStepNumber(websiteStore.stepNumber)"
                                v-if="form.termsAgreement == false">
                                {{
                                this.websiteStore.getFormBtnLabel }} <img src="../../assets/icons/arrow_right.svg" alt="">
                            </div>
                            <div class="col">

                                <button class="btn form-control btn-next p-lg-3" type="submit"
                                    v-if="form.termsAgreement">
                                    {{ this.websiteStore.getFormBtnLabel }} <img src="../../assets/icons/arrow_right.svg"
                                        alt="">
                                </button>

                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col rounded-1">
        <div class="row m-0">
            <div class="col pb-1 progress-bar--inactive"
                :class="{ 'progress-bar--active': websiteStore.stepNumber === 1 }">
            </div>
            <div class="col pb-1 progress-bar--inactive"
                :class="{ 'progress-bar--active': websiteStore.stepNumber === 2 }">
            </div>
            <div class="col pb-1 progress-bar--inactive"
                :class="{ 'progress-bar--active': websiteStore.stepNumber === 3 }">
            </div>
            <div class="col pb-1 progress-bar--inactive"
                :class="{ 'progress-bar--active': websiteStore.stepNumber === 4 }">
            </div>
        </div>
    </div>
    <div class="col fz-12  text-white pt-2">
        Step {{ this.websiteStore.stepNumber }}/4: {{ this.websiteStore.getStepNameLbl }}
    </div>
    <div class="position-fixed bottom-0 end-0 w-100 p-3 ">
        <div class="d-lg-flex justify-content-end">
            <div id="liveToast" class="toast text-bg-danger" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="3000"> 
                <div class="toast-header">
                  <strong class="me-auto pe-3">Please provide this information</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
 /* eslint-ignore-file */ 
import { useStore } from '@/store/landing';
import VueCountdown from '@chenfengyuan/vue-countdown';
import DatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { ref } from 'vue';
import TheTermsModal from './TheTermsModal.vue';
import axios from 'axios';
import Swal from 'sweetalert2'
import { useWebsiteStore } from '@/store/website';

export default {
    setup() {
        const MainWebsiteStore = useWebsiteStore();

        return {
            websiteStore: useStore(),
            MainWebsiteStore
        }
    },
    data() {
        return {
            number: this.websiteStore.getStepNumber,
            date: ref({
                day: new Date().getDay(),
                month: new Date().getMonth(),
                year: new Date().getFullYear()
            }),
            departureD: null,
            arrivalD: null,
            disableDatesForm: false,
            form: {
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                arrivalDate: null,
                departureDate: null,
                destination: "",
                campaignName: "",
                adID: "",
                termsAgreement: "",
                leadTypeID: 48,
        leadSourceChannelID: this.MainWebsiteStore.getChannel,
        leadSourceID: this.MainWebsiteStore.getSource,
        campaignName: this.MainWebsiteStore.campaignName
            }
        }
    },
    components: {
        VueCountdown,
        DatePicker,
        TheTermsModal
    },
    methods: {
        setDatesForm(a) {
            this.disableDatesForm = a
            if (a == true) {
                this.date = ''
                this.websiteStore.travelDate = null
                document.getElementById("datePickerComponent").classList.remove("is-invalid")
                this.websiteStore.travelDate = 1
            } else {
                this.websiteStore.travelDate = 0
            }
        },
        setDepartureDate() {
            this.departureDate = form.date.split('-')
        },
        submitForm() {
            var self = this;
            axios.create({ transformRequest: [(data, _headers) => JSON.stringify(data)] })
     .post('https://hooks.zapier.com/hooks/catch/4763102/2bekjdf', 
                this.form
            )
                .then(function (response) {
                    console.log(response);
                    self.sentMessage()
                    self.goToConfirmation()

                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        showValidationToast() {
            const toastLiveExample = document.getElementById('liveToast')
            //eslint-disable-next-line
            const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample)
            toastBootstrap.show()
        },
        errorMessage() {
      return Swal.fire({
        icon: "warning",
        title: "Oops",
        text: "An unexpected error ocurred, please try again.",
        iconColor: "#ffd700",
        showConfirmButton: false,
        timer: 5000,
        backdrop: `rgba(0,101,129,0.7)`,
      });
    },
    loadingMessage() {
      this.loading = Swal.mixin({
        customClass: {
          loader: 'spinner',
        }
      })
      return this.loading.fire({
        text: "Submitting",
        showConfirmButton: false,
        iconColor: "#0c9491",
        backdrop: `rgba(0,101,129,0.7)`,
        width: 160,
        timerProgressBar: true,
        // timer: 3000,
        didOpen: () => {
          this.loading.showLoading();
        },
      });
    },
    sentMessage() {
      return Swal.fire({
        icon: "success",
        title: "Thanks, all ready set.",
        text: "An agent will be in touch with you soon.",
        color: "#0C9491",
        showConfirmButton: false,
        timer: 5000,
        iconColor: "#0C9491",
        backdrop: `rgba(0,101,129,0.7)`,
      });
    },
    goToConfirmation() {
      this.$router.push('/confirmation')
    },
    },
    computed: {
        getDepartureDate() {
            this.setDepartureDate()
            return this.departureDate
        }
    },
    watch: {
        departureD(newQuestion, oldQuestion) {
            if (newQuestion != null && this.arrivalD != null) {
                // this.form.arrivalDate = newQuestion[0].toDateString()
                this.form.departureDate = newQuestion.toDateString()
                this.form.arrivalDate = this.arrivalD.toDateString()
                this.websiteStore.travelDate = 1
            } else {
                this.form.arrivalDate = ""
                this.form.departureDate = ""
                this.websiteStore.travelDate = 0
            }
        }
    },
    mounted() {
        // this.showValidationToast()
    }
}
</script>

<style scoped>
.timer {
    font-size: 12px;
    color: #ffff;
    background-color: #d50758;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
}

.timer-box {
    display: flex;
    justify-content: space-between;
}

.fz-12 {
    font-size: 12px;
}

.form-control {
    border-color: #0c9491;
}



.progress-bar--active {
    background-color: #0C9491 !important;
}

.progress-bar--inactive {
    background-color: #c8c8c8;
}

.form-check-input {
    width: 1.5rem !important;
    height: 1.5rem !important;
    ;
}

.tnc-lbl {
    color: #00A3FF;
    cursor: pointer;
}

input[type='radio'] {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    outline: none;
    border: 2px solid #d7d7d7;
}

input[type='radio']:before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    margin: 2px 2px;
    border-radius: 50%;
}

input[type="radio"]:checked:before {
    background: #0C9491;

}

input[type="radio"]:checked {
    border-color: #0C9491;
    border: 2px solid #d7d7d7;
}

.fs-14 {
    font-size: 14px;
}

.checkbox label {
    margin-bottom: 20px !important;
}

.roles {
    margin-bottom: 40px;
}

.input-slot-image {
    height: 20px;
    width: auto;

}

.dp__input_icon {
    cursor: pointer;
    position: absolute;
    top: 50%;
    inset-inline-start: 0;
    transform: translateY(-50%);
    color: var(--dp-icon-color);
    left: 91% !important;
}

.form-check-input:checked {
    background-color: #0C9491;
    border-color: #0C9491;
}

.bg-form {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    height: 197px;
}

@media (min-width: 992px) {
    .bg-form {
        height: 254px;
    }
}
</style>