<template>
    <slot />
    <TheFooter />
    <TheFixedMenu />
</template>

<script>
import TheFooter from '@/components/landing-page/TheFooter.vue'
import TheFixedMenu from '@/components/landing-page/TheFixedMenu.vue'
import "@/assets/main.css";

export default {
    components: {
        TheFooter,
        TheFixedMenu
    }
}
</script>

<style></style>