import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import HomeLanding from '../views/landing-page/HomeView.vue'
import DefaultLayout from '@/layout/DefaultLayout.vue'
import EsDefaultLayout from '@/layout/EsDefaultLayout.vue'
import LandingLayout from '@/layout/LandingLayout.vue'
import NotFoundView from '@/views/NotFoundView.vue'
import { useWebsiteStore } from '@/store/website'
import ConfirmationLayout from '@/layout/ConfirmationLayout.vue'
import ConfirmationPage from '../views/landing-page/ConfirmationView.vue'


const getTitleName = (to, _, next) => {
  const websiteStore = useWebsiteStore();
  let destination = null;
  let destinationSlug = to.params.destinationSlug || to.params.slug;

  //Se busca el destino por el nombre del slug
  if (destinationSlug) destination = websiteStore.destinations.find(x => x.slug == destinationSlug);

  //Se busca del paquete por el nombre del slug
  //En caso de no encontrar el destino se girara un 404
  if (destination) {
    if (to.params.packageSlug) {
      let pkg = destination.packages.find(x => x.slug == to.params.packageSlug)

      if (pkg) document.title += `: ${pkg.name}`;
      else next({ name: 'NotFoundView', params: null });
    } else document.title += `: ${destination.name}`;
    next();
    return;
  }

  next({ name: 'NotFoundView', params: null })
}
let routes = []

if (window.location.hostname.includes('promos') || window.location.search.includes('promos')) {
  routes = [
    {
      path: '/destinations/',
      name: 'home',
      component: HomeLanding,
      meta: {
        layout: LandingLayout,
        name: ''
      },
      children: [
        {
          path: ':slug/:type',
          component: HomeView,
        }
      ]
    },
    {
      path: '/confirmation',
      name: 'confirmation',
      component: ConfirmationPage,
      meta: {
        layout: ConfirmationLayout,
        name: ''
      }
    },
  ]
} else {
  routes = [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      meta: {
        layout: DefaultLayout,
        name: ''
      },
    },
    {
      path: '/:catchAll(.*)*',
      name: 'NotFoundView',
      component: NotFoundView,
      meta: {
        layout: DefaultLayout,
        name: '404 Page Not Found'
      },
    },
    {
      path: '/packages/:destinationSlug/:packageSlug',
      name: 'Package',
      component: () => import(/* webpackChunkName: "packageView" */ '../views/PackageView.vue'),
      meta: {
        layout: DefaultLayout,
        name: '',
        charset: 'utf-8'
      },
      beforeEnter: getTitleName,
      props: (route) => {
        let props = { ...route.params }
        // console.log(params);
        return props;
      }
    },
    {
      path: '/packages/:slug',
      name: 'destination',
      component: () => import(/* webpackChunkName: "destinationView" */ '../views/DestinationView.vue'),
      meta: {
        layout: DefaultLayout,
        name: ''
      },
      beforeEnter: getTitleName,
      props: true,
    },
    {
      path: '/request-a-quote',
      name: 'request',
      component: () => import(/* webpackChunkName: "quoteRequestView" */ '../views/QuoteRequest.vue'),
      meta: {
        layout: DefaultLayout,
        name: 'Request a Qoute'
      },
      props: true
    },
    {
      path: '/package-redeem',
      name: 'redeem',
      component: () => import(/* webpackChunkName: "packageRedeemView" */ '../views/PackageRedeem.vue'),
      meta: {
        layout: DefaultLayout,
        name: 'Redeem Package'
      },
      props: true
    },
    {
      path: '/get-deal',
      name: 'deal',
      component: () => import(/* webpackChunkName: "getDealView" */ '../views/GetDealView.vue'),
      meta: {
        layout: DefaultLayout,
        name: 'Get Deal'
      },
      props: true
    },
    {
      path: '/reviews',
      name: 'reviews',
      component: () => import(/* webpackChunkName: "reviewsView" */ '../views/ReviewsView.vue'),
      meta: {
        layout: DefaultLayout,
        name: 'Reviews'
      },
      props: true
    },
    {
      path: '/vip',
      name: 'vip',
      component: () => import(/* webpackChunkName: "vipView" */ '../views/VipView.vue'),
      meta: {
        layout: DefaultLayout,
        name: 'VIP Promotions'
      },
      props({ query }) {
        return {
          id: query.id
        }
      }
    },
    {
      path: '/faqs',
      name: 'faqs',
      component: () => import(/* webpackChunkName: "faqsView" */ '../views/FaqsView.vue'),
      meta: {
        layout: DefaultLayout,
        name: 'Frequently Answers Questions'
      },
      props: true
    },
    {
      path: '/terms-and-conditions',
      name: 'terms',
      component: () => import(/* webpackChunkName: "termsConditionsView" */ '../views/TermsConditionsView.vue'),
      meta: {
        layout: DefaultLayout,
        name: 'Terms and Conditions'
      },
      props: true
    },
    {
      path: '/privacy-policy',
      name: 'privacy',
      component: () => import(/* webpackChunkName: "privacyPolicyView" */ '../views/PrivacyPolicyView.vue'),
      meta: {
        layout: DefaultLayout,
        name: 'Privacy Policy'
      },
      props: true
    },
    {
      path: '/es/',
      children: [
        {
          path: 'loreto',
          name: 'loreto',
          component: () => import(/* webpackChunkName: "privacyPolicyView" */ '../views/HomeLoretoView.vue'),
          meta: {
            layout: EsDefaultLayout,
            name: 'loreto'
          },

          props: true
        },
        {
          path: 'paquetes/:destinationSlug/:packageSlug',
          name: 'Package-es',
          component: () => import(/* webpackChunkName: "packageView" */ '../views/es/PackageView.vue'),
          meta: {
            layout: EsDefaultLayout,
            name: ''
          },
          beforeEnter: getTitleName,
          props: (route) => {
            let props = { ...route.params }
            // console.log(params);
            return props;
          }
        },
        {
          path: 'reviews',
          name: 'resenias',
          component: () => import(/* webpackChunkName: "reviewsView" */ '../views/es/ReviewsView.vue'),
          meta: {
            layout: EsDefaultLayout,
            name: 'Reviews',
            charset: 'utf-8'
          },
          props: true
        },
        {
          path: 'personalizar',
          name: 'personalizar',
          component: () => import(/* webpackChunkName: "reviewsView" */ '../views/es/QuoteRequest.vue'),
          meta: {
            layout: EsDefaultLayout,
            name: 'Reviews',
            charset: 'utf-8'
          },
          props: true
        },
        {
          path: 'reservar',
          name: 'reservar',
          component: () => import(/* webpackChunkName: "reviewsView" */ '../views/es/PackageRedeem.vue'),
          meta: {
            layout: EsDefaultLayout,
            name: 'Reviews',
            charset: 'utf-8'
          },
          props: true
        },
        {
          path: 'conseguir-oferta',
          name: 'oferta',
          component: () => import(/* webpackChunkName: "reviewsView" */ '../views/es/GetDealView.vue'),
          meta: {
            layout: EsDefaultLayout,
            name: 'Reviews',
            charset: 'utf-8'
          },
          props: true
        },
        {
          path: 'solicitar-paquete',
          name: 'solicitar-paquete',
          component: () => import(/* webpackChunkName: "reviewsView" */ '../views/es/PackageRedeem.vue'),
          meta: {
            layout: EsDefaultLayout,
            name: 'Reviews',
            charset: 'utf-8'
          },
          props: true
        },
        {
          path: 'faqs',
          name: 'faqs-es',
          component: () => import(/* webpackChunkName: "reviewsView" */ '../views/es/FaqsView.vue'),
          meta: {
            layout: EsDefaultLayout,
            name: 'Reviews',
            charset: 'utf-8'
          },
          props: true
        },
        {
          path: 'vip-es',
          name: 'vip-es',
          component: () => import(/* webpackChunkName: "vip-es" */ '../views/es/VipView.vue'),
          meta: {
            layout: EsDefaultLayout,
            name: ''
          },
          beforeEnter: getTitleName,
          props: (route) => {
            let props = { ...route.params }
            // console.log(params);
            return props;
          }
        },
        {
          path: 'terminos-condiciones',
          name: 'terminos-y-condiciones',
          component: () => import(/* webpackChunkName: "termsConditionsView" */ '../views/es/TermsConditionsView.vue'),
          meta: {
            layout: EsDefaultLayout,
            name: 'Terms and Conditions'
          },
          props: true
        },
        {
          path: 'politica-privacidad',
          name: 'privacidad',
          component: () => import(/* webpackChunkName: "privacyPolicyView" */ '../views/es/PrivacyPolicyView.vue'),
          meta: {
            layout: EsDefaultLayout,
            name: 'Privacy Policy'
          },
          props: true
        },
        {
          path: 'vip',
          name: 'vip-es',
          component: () => import(/* webpackChunkName: "vipView" */ '../views/es/VipView.vue'),
          meta: {
            layout: EsDefaultLayout,
            name: 'VIP Promotions'
          },
          props({ query }) {
            return {
              id: query.id
            }
          }
        },
      ]
    }
  ]
}


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  }
})

router.beforeEach((to, _, next) => {
  let baseName = 'Vacation Deals All Inclusive';

  if (to.meta.name) {
    baseName += `: ${to.meta.name}`;
  }

  document.title = baseName;

  next();
})

export default router
