<template>
  <component :is="$route.meta.layout">
    <router-view />
  </component>
</template>

<script>
// import cookiesCard from './components/cookiesCard.vue';
import "@fancyapps/ui/dist/fancybox.css";
import { useWebsiteStore } from "@/store/website";

export default {
  setup() {
    const websiteStore = useWebsiteStore();

    return { websiteStore };
  },
  mounted() {
    this.$router.afterEach((to) => {
      this.websiteStore.setTeam(to.query.team);
      this.websiteStore.setSource(to.query.utm_source);
      this.websiteStore.setChannel(to.query.utm_medium);
      this.websiteStore.setCampaign(to.query.utm_campaign);
      this.websiteStore.getIsCampaign;
    })
  }
}
</script>