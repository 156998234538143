<template>
  <div class="container pt-md-3 pt-2">
    <form class="row g-md-3 g-1 px-5 needs-validation" id="lotteryForm" @submit.prevent="submitForm" novalidate>
      <div class="col-md-6">
        <input type="text" class="form-control" id="validationCustom01" placeholder="First Name" v-model="form.firstName" required>
        <div class="invalid-feedback text-white validation-fz">
            Please provide your First Name
          </div>
        <div class="valid-feedback validation-fz ">
          Looks good!
        </div>
      </div>
      <div class="col-md-6">
        <input type="text" class="form-control" id="validationCustom01" placeholder="Last Name" v-model="form.lastName" required>
        <div class="invalid-feedback text-white validation-fz">
            Please provide your Last Name
          </div>
        <div class="valid-feedback validation-fz ">
          Looks good!
        </div>
      </div>
      <div class="col-md-6">
        <input type="phone" class="form-control" id="validationCustom01" placeholder="Phone Number" v-model="form.phone"  maxlength="10" minlength="10" pattern="^\d{10}$" required>
        <div class="invalid-feedback text-white validation-fz">
            Please provide a Valid Phone Number
          </div>
        <div class="valid-feedback validation-fz ">
          Looks good!
        </div>
      </div>
      <div class="col-md-6">
        <input type="text" class="form-control" id="validationCustom01" :placeholder="prizeCode" disabled v-model="form.prizeCode" required>
      </div>
      <div class="col-md-12">
        <input type="email" class="form-control" id="validationCustom01" placeholder="Email" v-model="form.email" required>
        <div class="invalid-feedback text-white validation-fz">
            Please provide a Valid Email
          </div>
        <div class="valid-feedback validation-fz ">
          Looks good!
        </div>
      </div>
      <div class="col-12 pt-md-3 pb-md-4 pb-sm-2 pt-2">
        <div class="rounded-pill btn btn-shadow p-0 m-0 "><button class="rounded-pill btn  py-0 btn-spin-02 mb-0" type="submit">REGISTER NOW</button></div>
      </div>
    </form>
  </div>
</template>

<script>
import { registerLead } from '@/api/eplat';
import Swal from 'sweetalert2'
import { useWebsiteStore } from '@/store/website';

export default {
  props: [
    "prizeCode",
    "prizeName"
  ],
  setup() {
    return {
      websiteStore: useWebsiteStore()
    }
  },
  data() {
    return {
      form: {
        webhookID: "f91bb8e4-d255-edb5-a331-04467b565aa9",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        notes: "",
        campaignName: "fathers-day-2024-VDAI",
        leadTypeID: 114,
        leadSourceChannelID: this.websiteStore.getChannel,
        leadSourceID: this.websiteStore.getSource,
      }
    }
  },
  methods: {
    async submitForm({ target }) {
      if (target.checkValidity()) {
        this.form.notes = "Prize: " + this.prizeName + " | Prize Code: " + this.prizeCode
        this.loadingMessage()
        await registerLead(this.form)
          .then(() => {
            //console.log(res);
            this.alertMessage()
            this.resetValues()
            localStorage.setItem('mothersDayPrize', true)
          })
          .catch((error) => {
            console.log(error);
            this.errorMessage()
          }).finally(() => {
            //Perform action in always
          });

      } else target.classList.add('was-validated')

    },
    errorMessage() {
      return Swal.fire({
        icon: "warning",
        title: "Oops",
        text: "An unexpected error ocurred, please try again.",
        iconColor: "#ef527d",
        showConfirmButton: false,
        timer: 5000,
        backdrop: `rgba(239,82,125,0.7)`,
      });
    },
    loadingMessage() {
      this.loading = Swal.mixin({
        customClass: {
          loader: 'spinner',
        }
      })
      return this.loading.fire({
        text: "Submitting",
        showConfirmButton: false,
        iconColor: "#8d5cab",
        color: "#001f65",
        backdrop: `rgba(0, 31, 101, 0.7)`,
        width: 160,
        timerProgressBar: true,
        // timer: 3000,
        didOpen: () => {
          this.loading.showLoading();
        },
      });
    },
    alertMessage() {
      localStorage.setItem('easterPrize', true );
      this.tab = 3
      return Swal.fire({
        icon: "success",
        title: "Thanks, all ready set.",
        text: "An agent will be in touch with you soon.",
        color: "#001f65",
        showConfirmButton: false,
        timer: 5000,
        iconColor: "#001f65",
        backdrop: `rgba(0, 31, 101, 0.7)`,
      });
    },
    initialValues() {
      return {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        prizeCode: null
      };
    },
    resetValues() {
      this.form = this.initialValues();
      document.getElementById("lotteryForm").classList.remove("was-validated");
    },
  },
  mounted() {

  }
}
</script>

<style scoped>
.btn-spin {
  background-color: #ffff;
  font-family: Bebas Neue;
  color: #f7bf08;
  font-size: 40px;
  box-shadow: -1px 20px 29px 0px rgba(rgb(239, 165, 13));
  -webkit-box-shadow: -1px 20px 29px 0px rgba(239, 165, 13 0.49);
  -moz-box-shadow: -1px 20px 29px 0px rgba(rgb(239, 165, 13));
  border: solid 2px #f7bf08;
}

.btn-spin-02 {
  background-color: #fff;
  font-family: Bebas Neue;
  color: #dc2834;
  border-color: #c5014a!important;
  font-size: 40px;
  box-shadow: 2px 11px 5px 6px rgba(153, 28, 81, 1);
  -webkit-box-shadow: 1px 10px 0px -1px rgba(153, 28, 81, 1);
  -moz-box-shadow: 2px 11px 5px 6px rgba(153, 28, 81, 1);
}

@media only screen and (max-width: 600px) {
  .btn-spin {
  font-size: 30px;
}
}

.btn-shadow {
  box-shadow: 1px 25px 45px 1px rgba(239, 165, 13,0.25);
-webkit-box-shadow: 1px 25px 45px 1px rgba(239, 165, 13,0.25);
-moz-box-shadow: 1px 25px 45px 1px rgba(239, 165, 13,0.25);
}
.swal2-loader {
  border-color: #ef527d!important;
}

.validation-fz {
  font-size: 11px;
  color: white!important;
}
</style>