<template>
  <DestinationFilter />
  <section id="bestDealsPackages">
    <div class="container my-5">
      <div class="row gy-4">
        <div class="col-12 text-center">
          <h2 class="fs-4"><b>Get the Best Deal</b></h2>
          <TheRandomIcon :type="2"/>
        </div>
        <PackageCard v-for="packages in bestDeals" :key="packages.id" :package="packages"
          :destinationName="packages.destinationName" :destinationSlug="packages.destinationSlug" :isVip="0"
          class="col-md-6 col-lg-4" />
      </div>
    </div>
  </section>
  <section id="vipPromotions" class="vip-promo-banner pt-3" :style="getBlockStyle">
    <div class="container">
      <div class="col-lg-7 col-xl-6 mx-auto">
        <div class="row justify-content-center">
          <p class="vip-title text-center pt-5 text-center d-md-none">
            <span class="vip-label">VIP</span> Promotions
          </p>
          <div class="col-md-6 pb-4 pb-lg-0">
            <img src="@/assets/icons/giftVIP.png" width="250" alt="" class="img-gift" />
          </div>
          <div class="col-md-6 text-center text-md-start" style="margin-top: -1.5rem;">
            <p class="vip-title d-none d-md-block ">
              <span class="vip-label">VIP</span> Promotions
            </p>
            <p class="pb-3">
              We have exclusive promotions such as vacation packages at
              special prices or incredible amenities for our most loyal
              travelers.
            </p>
            <router-link :to="{ name: 'vip' }" class="btn btn-check-promo py-3 px-4 mb-3" v-if="websiteStore.isVip">
              CHECK PROMOTIONS
            </router-link>
            <button class="btn btn-check-promo py-3 px-4 mb-3" data-bs-toggle="modal" data-bs-target="#vipModal" v-else>
              CHECK PROMOTIONS
            </button>
            <TheRandomIcon :type="3"/>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="container py-5">
    <section id="bookWithUs" class="mb-5">
      <div class="row text-center gy-5">
        <h2><b>WHY BOOK WITH US ?</b></h2>
        <div class="col-md-6 col-lg-3">
          <img src="~@/assets/icons/savings-icon.svg" alt="" width="175" />
          <p class="book-title">Big Savings</p>
          <p>
            You will save 80% of your expenses with our vacation packages.<br />
          </p>
        </div>
        <div class="col-md-6 col-lg-3">
          <img src="~@/assets/icons/price-tag-icon.svg" alt="" width="175" />
          <p class="book-title">Best Price Policy</p>
          <p>
            We check the competition every day and we have the meet or beat
            guarantee.
          </p>
        </div>
        <div class="col-md-6 col-lg-3">
          <img src="~@/assets/icons/credit-card-icon.svg" alt="" width="175" />
          <p class="book-title">Secure Transactions</p>
          <p>
            Our verifications department requires a digital audio receipt
            along with billing and shipping information.
          </p>
        </div>
        <div class="col-md-6 col-lg-3">
          <img src="~@/assets/icons/resort-icon.svg" alt="" width="175" />
          <p class="book-title">Work directly with the Resorts</p>
          <p>
            We are the main brokers in Puerto Vallarta and Cancun for the
            resorts we promote.
          </p>
        </div>
      </div>
    </section>
    <section id="reviews">
      <div class="row text-center p-3">
        <h2><b>Reviews</b></h2>
      </div>
      <ReviewCard />
    </section>
    <section id="WeatherWidget">
      <div class="row text-center p-3">
        <h2><b>Destination Weather</b></h2>
      </div>
      <WeatherCard />
    </section>
    <!-- <section id="FlightsWidget">
      <FlightsCard />
    </section> -->
  </div>
  <div class="bg-white pt-4 ps-5 pe-5 pb-5">
    <div class="text-center">
      <h2><b>Affiliations</b></h2>
      <Swiper v-bind="swiperOptions" :modules="modules" class="col-lg-8 mx-auto">
        <SwiperSlide>
          
            <img src="@/assets/images/footer/bbb-rating.webp" alt="" width="180" />
   
        </SwiperSlide>
        <SwiperSlide>
          <img src="@/assets/images/footer/asudestico.webp" alt="" width="180" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="@/assets/images/footer/adeprotur.webp" alt="" width="180" />
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'swiper/vue';
import DestinationFilter from "@/components/DestinationFilter.vue";
import PackageCard from "@/components/PackageCard.vue";
import ReviewCard from "@/components/ReviewCard.vue";
// import FlightsCard from "@/components/FlightsCard.vue";
import WeatherCard from "@/components/WeatherCardCarousel.vue";
import { useWebsiteStore } from '@/store/website';
import { Pagination  } from 'swiper';

export default {
  setup() {
    const websiteStore = useWebsiteStore();

    return {
      websiteStore,
      modules: [ Pagination ]
    }
  },
  props: {
    slug: {
      type: String
    },
  },
  data() {
    return {
      destinations: this.websiteStore.destinations,
      swiperOptions: {
        pagination: {
          dynamicBullets: true,
        },
        autoplay: true,
        breakpoints: {
          768: {
            slidesPerView: 3,
            loop: false
          }
        }
      },
      blockCampaignStyle: ''
    }
  },
  name: "HomeView",
  components: {
    DestinationFilter,
    PackageCard,
    ReviewCard,
    // FlightsCard,
    WeatherCard,
    Swiper,
    SwiperSlide
  },
  computed: {
    bestDeals() {
      return this.websiteStore.getPackages({ take: 6 });
      // let packages = [];
      // this.websiteStore.destinations.forEach((dest) => {
      //   dest.packages.forEach((pkg) => {
      //     if (pkg.includeInHome) {
      //       let _package = { ...pkg };
      //       // _package.slug = dest.slug + "/" + pkg.slug;
      //       _package.destination = dest.name;
      //       _package.destinationSlug = dest.slug
      //       packages.push(_package);
      //     }
      //   });
      // });
      // return packages;
    },
    getBlockStyle() {
      this.setBlockColor()
      return this.blockCampaignStyle
    }
  },
  methods: {
    setBlockColor() {
      if(this.websiteStore.isCampaign) {
        this.blockCampaignStyle=this.websiteStore.campaign.blocksColor
      }
    }
  }
};
</script>

<style scoped>
.vip-promo-banner {
  background-color: #0c9491;
  color: #fff;
}

.vip-label {
  color: #ceb082;
  font-weight: bold;
}

.vip-title {
  font-size: 24px;
  padding-top: 50px;
}

.vip-p {
  padding-right: 53%;
}

.btn-check-promo {
  font-size: 12px;
  background-color: #ceb082;
  font-weight: 600;
}

.img-gift {
  float: right;
}

p {
  font-size: 16px;
}

.book-title {
  padding-top: 15px;
  color: #0c9491;
  font-size: 20px;
  margin-bottom: 0px;
}

@media (max-width: 768px) {
  .vip-p {
    text-align: center;
    margin: auto;
    width: 80%;
    padding: 10px;
    padding-top: 0px;
  }

  .img-gift {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    float: none;
  }
}
.bg-campaign {
  background-color: #023c61;
}

.bg-campaign-02{
  background-color: #023c61
}
</style>
