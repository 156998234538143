<template>
  <div>


    <!-- Modal Wheel -->
    <div class="modal fade z-3" id="modalBannerWheel" tabindex="-1" aria-labelledby="modalBannerLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered z-3">
        <div class="modal-content backgr rounded-0">
          <div class="text-end">
            <button type="button" class="btn-close m-2 position-absolute end-0" data-bs-dismiss="modal"
              aria-label="Close" id="closeLotteryModal"> <i class="bi bi-0-circle"></i></button>
            <!-- <div class="col-12 bg-title pt-4 pb-3 title-font text-center">
                  <img src="../assets/images/campaigns/mothers-day/title-01.png" alt="" class="w-25">
                </div> -->
            <div class="text-center pt-1 pt-lg-5">
              <img src="../assets/images/campaigns/fathers-day/title-01.png" alt="" class="w-50 pb-3">
              <h4 class="skranji-regular text-white tf-1 mb-0">SHOW DAD HOW MUCH</h4>
              <h1 class="dancing-script text-white">he Rocks</h1>
              <h4 class="skranji-regular text-white tf-1 mb-0">WITH THE PERFECT GIFTS</h4>
            </div>
          </div>
          <div class="modal-body p-0 text-center bg-pink-gradient" style="--bs-bg-opacity: .5;">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12 text-center pt-0 pb-0 flash">

                  <div id="carouselExampleAutoplaying" class="carousel slide carousel-fade pb-0"
                    data-bs-ride="carousel">
                    <img src="../assets/images/campaigns/fathers-day/icon.gif" class="pot tilt-n-move-shaking" alt=""
                    @click="showModal('closeModal', 'modalLotteryPrize', true)">
                  </div>
                  <!-- <div class="btn btn-shadow p-0 m-0 rounded-pill bg-white" ><button class=" btn px-4 py-0 btn-spin mb-0"
                      type="submit" @click="stopSlider()">FIND OUT!</button></div> -->

                  <!-- <h3 class="title-font pb-0 mb-0">CLICK THE GOLD POT TO</h3>
                      <span class="special-title-font">Reveal your Prize</span> -->
                  <!-- <div class="col pb-0 mb-0"><img src="../assets/images/winwheel/valentines/pointer.png" alt=""
                                class="w-25 position-absolute pointer"></div>
                          </div> -->
                  <!-- <div class="col bg-pot pb-0">
                      <img src="../assets/images/campaigns/may_lottery/piniata-full.png" alt=""
                        class="w-75 tilt-n-move-shaking pot"
                        @click="showModal('closeModal', '#modalLotteryPrize', true)">
                    </div> -->
                </div>
                <div class="row p-0 m-0">
                  <div class="col-9 text-start">
                    <p class="terms-p montserrat-font">
                      Does not apply with other promotions. <br>
                      Valld with the purchase of a <br>
                      Vacation Package to Mexico. <br>
                      *Only with the purchase of a $599 package.
                    </p>
                  </div>
                  <div class="col-3 text- ">
                    <img src="../assets/images/campaigns/fathers-day/logo.png" alt=""
                      class="position-absolute logo-vdai">
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- Modal Prize -->
    <div class="modal fade " id="modalLotteryPrize" tabindex="-1" aria-labelledby="modalLotteryPrize"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content backgr-color rounded-0">
          <div class="text-end">
            <button type="button" class="btn-close m-2 position-absolute end-0" data-bs-dismiss="modal"
              aria-label="Close" id="closePrizeModal"></button>
            <div class="col-12  bg-title pt-2 pb-2 title-font montserrat-font-bold text-center">
              <span class="skranji-regular text-uppercase">Congrats!</span>
            </div>
          </div>
          <div class="modal-body p-0 text-center bg-pink-gradient" style="--bs-bg-opacity: .5;">
            <div class="container-fluid">
              <div class="row text-center">
                <div class="col-12 bg-prize pt-0 pt-lg-3 pb-3">
                  <img :src="this.prize.imgUrl" alt="" class="w-50 pt-lg-1 pt-3 pb-lg-0">
                </div>
                <div class="col">
                  <span class="title-font condition">*Valid with the purchase of a Vacation Package to Mexico.</span>
                  <lotteryForm :prizeCode="this.prize.code" :prizeName="this.prize.name" />
                  <h3 class="code text-uppercase text-white pt-3 pb-3">to redeem your prize!</h3>
                </div>
              </div>
            </div>
            <!-- <div class="col-12 bg-dark-pink pt-md-4 pb-md-3">
                <h1>congrats!</h1>
              </div>
              <img :src="prizeImageUrl" alt="" class="w-75 pt-md-4 pt-2 pb-md-3 pb-2">
              <h6>*Valid with the purchase of a Vacation Package to Mexico.</h6>
              <lotteryForm :prizeCode="this.prizeLbl" :prizeName="prizeName" />
              <h1 class="code">to redeem your prize!</h1> -->
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Thanks for playing -->
    <div class="modal fade " id="modalLotteryPrize" tabindex="-1" aria-labelledby="modalLotteryPrize"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content backgr rounded-0">
          <div class="text-end">
            <button type="button" class="btn-close m-2 position-absolute end-0" data-bs-dismiss="modal"
              aria-label="Close" id="closePrizeModal"></button>
            <div class="col-12  bg-title pt-4 pb-0 title-font montserrat-font-bold text-center">
              <span class="lobster-regular">Congrats!</span>
            </div>
          </div>
          <div class="modal-body p-0 text-center bg-pink-gradient" style="--bs-bg-opacity: .5;">
            <div class="container-fluid">
              <div class="row text-center">
                <div class="col-12 bg-prize pt-0 pb-3">
                  <img :src="this.prize.imgUrl" alt="" class="w-75 pt-lg-1 pt-3 pb-lg-0">
                </div>
                <div class="col">
                  <span class="title-font condition">*Valid with the purchase of a Vacation Package to Mexico.</span>
                  <lotteryForm :prizeCode="this.prize.code" :prizeName="this.prize.name" />
                  <h3 class="code text-uppercase text-white pt-3 pb-3">to redeem your prize!</h3>
                </div>
              </div>
            </div>
            <!-- <div class="col-12 bg-dark-pink pt-md-4 pb-md-3">
                <h1>congrats!</h1>
              </div>
              <img :src="prizeImageUrl" alt="" class="w-75 pt-md-4 pt-2 pb-md-3 pb-2">
              <h6>*Valid with the purchase of a Vacation Package to Mexico.</h6>
              <lotteryForm :prizeCode="this.prizeLbl" :prizeName="prizeName" />
              <h1 class="code">to redeem your prize!</h1> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lotteryForm from '@/components/TheLotteryForm.vue'
import Confetti from 'canvas-confetti'
import { useWebsiteStore } from '@/store/website';

export default {
  setup() {
            const websiteStore = useWebsiteStore();

            return {
                websiteStore,
            }
        },
  data() {
    return {
      prize: {
        code: '',
        name: '',
        imgUrl: ''
      },
      defaults: {
        spread: 360,
        ticks: 50,
        gravity: 0,
        decay: 0.94,
        startVelocity: 30,
        colors: ['FFE400', 'FFBD00', 'E89400', 'FFCA6C', 'FDFFB8']
      },
      hasPlayed: localStorage.getItem("mothersDayPrize"),
      carousel: ''
    }
  },
  components: {
    lotteryForm
  },
  methods: {
    showModal(close, open, confetti) {
      if (confetti) {
        Confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 },
          zIndex: 2000,
          shapes: ['circle'],
          colors: ['#f7bf08']
        })
      }

      if (this.hasPlayed == 'true') {
        console.log('PLAYED')
      } else {
        // this.getFlashlightEffect()
        let pagebutton = document.getElementById('closeLotteryModal');
        pagebutton.click();
        let bannerModal = document.getElementById(open);
        //eslint-disable-next-line
        const bannerModalInstance = new bootstrap.Modal(bannerModal);
        bannerModalInstance.show();
      }
    },
    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },
    setPrize() {
      switch (this.getRandomInt(4)) {
        case 0:
          this.prize.code = '#VALCRUISE',
            this.prize.name = 'FREE CRUISE',
            this.prize.imgUrl = require('@/assets/images/campaigns/fathers-day/prizes/prize-01.png')
          break;
        case 1:
          this.prize.code = '#VAL1OODIS',
            this.prize.name = '$100 usd DISCOUNT',
            this.prize.imgUrl = require('@/assets/images/campaigns/fathers-day/prizes/prize-02.png')
          break;
        case 2:
          this.prize.code = '#VALVEGAS',
            this.prize.name = 'FREE VACATION IN LAS VEGAS',
            this.prize.imgUrl = require('@/assets/images/campaigns/fathers-day/prizes/prize-03.png')
          break;
        case 3:
          this.prize.code = '#VAL75DIS'
          this.prize.name = '$75 usd DISCOUNT',
            this.prize.imgUrl = require('@/assets/images/campaigns/fathers-day/prizes/prize-04.png')
          break;
        case 4:
          this.prize.code = '#VAL50DlS',
            this.prize.name = '$50 usd DISCOUNT',
            this.prize.imgUrl = require('@/assets/images/campaigns/fathers-day/prizes/prize-05.png')
          break;
        default:
          break;
      }
    },
    sliderAnimation() {
      const myCarouselElement = document.getElementById("carouselExampleAutoplaying")
      /*eslint-disable-next-line */
      this.carousel = new bootstrap.Carousel(myCarouselElement, {
        interval: 10,
        touch: false,
        pause: false
      })
    },
    stopSlider() {
      this.carousel.pause()
      console.log(this.carousel._activeElement.id)
      this.getFlashlightEffect()
      this.setPrize(this.carousel._activeElement.id)
      setTimeout(() => {
        this.showModal('closeModal', 'modalLotteryPrize')
      }, "1000");
    },
    getFlashlightEffect() {
      document.getElementById("inn").classList.add("shutterClick");
      setTimeout(function () {
        document.getElementById("inn").classList.remove("shutterClick");
      }, 900);
    }
  },
  mounted() {
    // if (this.websiteStore.campaign.fromDate < this.websiteStore.now && this.websiteStore.campaign.toDate > this.websiteStore.now) {
    // this.setPrize()
    // this.showModal('closeLotteryModal', 'modalBannerWheel')
    // }


    // this.showModal('closeLotteryModal', 'modalLotteryPrize')
    // this.sliderAnimation()
  }
}
</script>

<style scoped>
.backgr {
  /* background: rgb(255, 180, 167);
  background: radial-gradient(circle, rgba(255, 180, 167, 1) 0%, rgba(255, 128, 135, 1) 100%); */
  background-image: url(../assets/images/campaigns/fathers-day/bg.jpg);
  background-size: cover;
}

.backgr-color {
background-color: #790005;
}

.bg-title {
  background-color: #e42129;
}

.title-font {
  color: #ffef;
}

.special-title-font {
  font-family: Cabinet;
  color: #9cd517;
  font-size: 290%;
}

.bg-pot {
  /* background-image: url(../assets/images/campaigns/may_lottery/bg-pot.png); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.bg-prize {
  /* background-color: #39720b; */
}

.condition {
  font-size: 12px;
}

.special-bonus {
  color: #812ea8;
  font-weight: bold;
}

.montserrat-font-bold {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
}

.skranji-regular {
  font-family: "Skranji", system-ui;
  font-weight: 400;
  font-style: normal;
}

.dancing-script {
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.dancing-script.text-white {
  text-shadow: red 3px 0px 0px;
}


.montserrat-font {
  font-family: 'Montserrat', sans-serif;
}

.terms-p {
  font-size: 12px;
}

.logo-vdai {
  right: -1px;
  bottom: -1px;
  width: 15%;
}

.btn-shadow {
  box-shadow: -1px 20px 29px 0px rgba(rgb(199, 67, 78));
  -webkit-box-shadow: -1px 20px 29px 0px rgba(199, 67, 78, 0.49);
  -moz-box-shadow: -1px 20px 29px 0px rgba(rgb(199, 67, 78));
  border: 1px solid #c7434e;
}

.btn-spin {
  font-family: Bebas Neue;
  color: #c7434e;
  font-size: 30px;

}

.shutterClick {
  animation: cameraFlash .3s .3s alternate-reverse 1;
}

.intermitente{
  border: 1px solid black;
  padding: 20px 20px;
  box-shadow: 0px 0px 20px;
  animation: infinite resplandorAnimation 2s;
  
}
@keyframes resplandorAnimation {
  0%,100%{
    box-shadow: 0px 0px 20px;
  }
  50%{
  box-shadow: 0px 0px 0px;
  
  }

}

@keyframes cameraFlash {
  from {
    box-shadow: 0;
  }

  to {
    box-shadow: inset 0 0 0 100px #ffff;
  }
}

.carousel-item.active.shutterClick:before {
  content: '';
  animation: cameraFlash .3s .3s alternate-reverse 1;
}

.lobster-regular {
  font-family: "Lobster", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 60px !important;
}

.skranji-regular.text-uppercase {
  font-size: 35px !important;
}


@media only screen and (max-width: 600px) {
  .condition {
    font-size: 10px;
  }

  .lobster-regular {
    font-family: "Lobster", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 40px !important;
  }
}

@keyframes tilt-n-move-shaking {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }

  25% {
    transform: translate(5px, 5px) rotate(5deg);
  }

  50% {
    transform: translate(0, 0) rotate(0eg);
  }

  75% {
    transform: translate(-5px, 5px) rotate(-5deg);
  }

  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

.pot.tilt-n-move-shaking {
  animation: tilt-n-move-shaking 0.7s infinite;
}

/* .pot {
  padding-top: 39%;
} */
</style>